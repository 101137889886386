<template>
  <nav class="tagHolder">
    <div :class="[ isLG ? 'container-fluid d-flex justify-content-center' : 'container-fluid horizontal-scroll']">
      <div :class="[ isXS ? 'wrap-mobile' : 'wrap']">
        <div v-if="isXS" :class="['mobile-drop', opened ? 'on' : '']">
          <div v-if="selectedCategory" class="current">{{ selectedCategory.label }}</div>
          <b-btn variant="droplist" @click="opened = !opened">
            <i class="icon-arrow-up" />
            <i class="icon-close" />
          </b-btn>
        </div>
        <ul>
          <!-- https://jsfiddle.net/cfjawyvv/ -->
          <!-- @TODO: clicked button get textcontent and addClass active, remove active for others, textcontent add to div class current. -->
          <li v-for="(category, cIndex) in categories" :key="cIndex"><b-btn :class="['btn-filter', (selectedCategory.id === category.id ? 'active' : '')]" @click="selectCategory(category)">{{ category.label }}</b-btn></li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  export default {
    data: function()
    {
      return {
        selectedCategory: {
          id: undefined,
          label: this.$t("restFilter.all")
        },
        categories: [
          {
            id: undefined,
            label: this.$t("restFilter.all")
          },{
            id: 1,
            label: this.$t("restFilter.pizza")
          },{
            id: 2,
            label: this.$t("restFilter.palanet")
          },{
            id: 3,
            label: this.$t("restFilter.fastfood")
          },{
            id: 4,
            label: this.$t("restFilter.pasta")
          },{
            id: 5,
            label: this.$t("restFilter.salads")
          },{
            id: 6,
            label: this.$t("restFilter.coocked")
          },{
            id: 7,
            label: this.$t("restFilter.garnish")
          },{
            id: 8,
            label: this.$t("restFilter.desserts")
          }
        ],
        isLG: typeof window !== 'undefined' ? window.innerWidth > 1170 : true,
        isSM: typeof window !== 'undefined' ? window.innerWidth < 768 : false,
        isXS: typeof window !== 'undefined' ? window.innerWidth < 571 : false,
        opened: false,
        offset: {
          top: 61,
          bottom: 20
        },
        zindex: 100
      }
    },
    watch: {
      '$route' () {
        this.opened = false
      }
    },
    methods: {
      selectCategory(category)
      {
        this.selectedCategory = category
        this.opened = !this.opened;
        this.$emit('change', category)
        /**
         * parent => <sticky-filter @change="localFunction"></sticky-filter>
         *
         * methods: {
         *     localFunction(category) ...
         * }
         */
      }
    }
  };
</script>
