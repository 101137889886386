<template>
  <client-only>
    <header class="logged app-header">
      <b-navbar toggleable="lg" type="light">
        <b-navbar-toggle target="nav-collapse" />
        <ul class="nav mx-auto ml-lg-auto mr-lg-0 justify-content-start justify-content-lg-end navigation"></ul>
        <button type="button" :class="['btn btn-cart icon-restaurant-products icon-fast-food']" title="Produse" @click="showDetail('products')">
        </button>
        <button type="button" :class="['btn btn-cart icon-restaurant-info icon-info-circled']" title="info" @click="showDetail('info')">
        </button>
        <nuxt-link :to="{name: 'embed-cart'}" :class="['btn btn-cart icon-cart', cartSize ? 'cart-wiggle' : '']" variant="outline">
          <span v-if="cartSize" class="cart-item">{{ cartSize }}</span>
        </nuxt-link>
      </b-navbar>
    </header>
  </client-only>
</template>
<script>
  import Header from '@/components/common/Header.vue';
  export default {
    extends: Header,
    data: function(){
      return {
        isDesktop: typeof window !== 'undefined' ? window.innerWidth > 991 : true,
        embeddedRestaurantSlug: undefined,
        showCollapse: false,
        cart: []
      }
    },
    computed: {
      cartSize() {
        const cartNR = this._.size(this.cart)
        return cartNR > 99 ? "99+" : cartNR;
      }
    },
    mounted() {
      let that = this
      this.$root.$on('updateCartObject', function(cartObject){
        that.populateCart(cartObject)
      })
      this.$store.watch(
        function (state) {
          return state.embed;
        },
        function () {
          that.embeddedRestaurantSlug = that.$store.getters['embed/embeddedRestaurantSlug']
        },
        {
          deep: true
        }
      );
      that.embeddedRestaurantSlug = that.$store.getters['embed/embeddedRestaurantSlug']
    },
    methods: {
      populateCart ({content})
      {
        this.cart = [];
        for(var cartItem in content) {
          this.cart.push(content[cartItem]);
        }
      },
      showDetail(screen) {
        if(this.$route.name !== 'embed-restaurant-detail') {
          this.$router.push({name: 'embed-restaurant-detail', params: {slug: this.embeddedRestaurantSlug, screen: screen}})
        }
        this.$nextTick(() => this.$root.$emit('changeEmbedView', { screen }))
      }
    }
  }
</script>
