<template>
  <client-only>
    <div :id="faq.slug" class="row">
      <div class="col-12 content-sectiom">
        <h5 class="title" @click="opened = !opened">{{ faq.title }}</h5>
        <!-- eslint-disable vue/no-v-html -->
        <p class="paragraph" v-html="faq.content"></p>
      </div>
    </div>
  </client-only>
</template>

<script>
  export default {
    props: {
      faq: {
        type: Object,
        default: function()
        {
          return {};
        }
      }
    }
  };
</script>
