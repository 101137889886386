<template>
  <b-container class="px-0">
    <div class="bullets">
      <div v-if="isDesktopBullet" class="bullet-slider">
        <div v-for="(category, cIndex) in categories" :key="cIndex" class="bullet-slide">
          <b-btn class="circle" :style="_.get(inlineStyles, category.slug, inlineStyles['all'])" @click="navigateToCategory(category.id)">
            <span>{{ category.title }}</span>
          </b-btn>
        </div>
      </div>
      <div v-else-if="categories.length">
        <vue-slick id="bulletSlider" ref="bulletSlider" :options="bulletSliderOptions" class="bullet-slider" @init="handleInit" @afterChange="handleAfterChange">
          <div v-for="(category, cIndex) in categories" :key="cIndex" class="bullet-slide">
            <b-btn class="circle" :style="_.get(inlineStyles, category.slug, inlineStyles['all'])" @click="navigateToCategory(category.id)">
              <span>{{ category.title }}</span>
            </b-btn>
          </div>
        </vue-slick>
        <div :class="['nav-wrap', totalslide > 1 ? 'd-flex' : 'd-none']">
          <div class="nav-wrap-bullet">
            <div class="counter"><span>{{ currentSlide }}</span><span>&nbsp;/&nbsp;</span><span>{{ totalslide }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
  export default {
    data: function(){
      return {
        inlineStyles: {
          'pizza':{backgroundImage:"url(/img/square/pizza.jpg)"},
          'fructe-de-mare':{backgroundImage:"url(/img/square/fructemare.jpg)"},
          'fast-food':{backgroundImage:"url(/img/square/fastfood.jpg)"},
          'salate':{backgroundImage:"url(/img/square/salate.jpg)"},
          'supe':{backgroundImage:"url(/img/square/supe.jpg)"},
          'peste':{backgroundImage:"url(/img/square/peste.jpg)"},
          'deserturi':{backgroundImage:"url(/img/square/desert.jpg)"},
          'traditional':{backgroundImage:"url(/img/square/traditionale.jpg)"},
          'mic-dejun':{backgroundImage:"url(/img/square/micdejun.jpg)"},
          'mancare-asiatica':{backgroundImage:"url(/img/square/asian.jpg)"},
          'meniul-zilei':{backgroundImage:"url(/img/square/meniulzilei.jpg)"},
          'paste':{backgroundImage:"url(/img/square/paste.jpg)"},
          'garnituri':{backgroundImage:"url(/img/square/garnituri.jpg)"},
          'bauturi':{backgroundImage:"url(/img/square/bauturi.jpg)"}
        },
        isDesktopBullet: typeof window !== 'undefined' ? window.innerWidth > 589 : true,
        bulletSliderOptions: {
          slidesToShow: 1,
          autoplay:true,
          arrows:true,
          autoplaySpeed: 5000,
          appendArrows:".nav-wrap-bullet",
          prevArrow:'<button type="button" class="slick-prev icon-arrow-left order-1"></button>',
          nextArrow:'<button type="button" class="slick-next icon-arrow-right order-3"></button>',
          responsive:true,
          centerMode:true,
          centerPadding:'30px',
          variableWidth:true,
          mobileFirst:true
        },
        totalslide: undefined,
        currentSlide: 1,
        categories: []
      };
    },
    async mounted() {
      this.getBaseCategories()
    },
    methods: {
      navigateToCategory(categoryId) {
        this.$store.dispatch('restaurantType/setRestaurantCategoryId', { categoryId }).then(() => {
          this.$router.replace({name: 'restaurants'})
        })
      },
      handleInit(event,slick) {
        this.totalslide = slick.slideCount;
      },
      handleAfterChange(event, slick, currentSlide) {
        this.currentSlide = currentSlide + 1;
      },
      async getBaseCategories(coords) {
          let locationCoords = {};
          try {
              locationCoords = {
                  lat: this.$store.state.location.coords.latitude ? this.$store.state.location.coords.latitude : null,
                  lng: this.$store.state.location.coords.longitude ? this.$store.state.location.coords.longitude : null,
              }
          }catch (e) { }

          let fields = [
              'base_category.id',
              'base_category.title',
              'base_category.slug',

          ];
          let withs = [
              'base_categories'
          ];

          await this.$api.getRestaurants(fields, withs, [], {}, locationCoords).then(result => {
              var categories = []
              this._.get(result, 'data.data').map((restaurant) => {
                  categories = this._.concat(categories, this._.get(restaurant, 'base_categories.data', []))
              })
              categories = this._.uniqBy(categories, function (category) {
                  return category.id;
              });
              this.categories = categories
          }).catch((err) => {
              console.error(err);
          })
      }
    }
  };
</script>
