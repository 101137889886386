<template>
  <div :style="headerImg" class="header-image d-flex flex-column justify-content-center">
    <LocationSelectorHome></LocationSelectorHome>
    <div class="bottom-absolute">
      <b-container>
        <b-row class="flex-column">
          <b-col xs="12" class="text-center">
            <Statistics v-if="showStatistics"></Statistics>
          </b-col>
          <b-col xs="12" class="text-center">
            <nuxt-link :to="{name: 'restaurants'}" class="btn btn-location">{{ $t('homePage.viewLocations') }}</nuxt-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
  export default {
    data: function(){
      return {
        headerImg:{ backgroundImage:"url(/img/headerImages/headerImg1.jpg)" },
        windowSize: (typeof(window) === "undefined" ? true : window.innerWidth),
        showStatistics: (typeof(window) === "undefined" ? true : (window.innerWidth > 767))
      };
    }
  };
</script>
