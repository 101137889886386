<template>
  <div>
    <b-btn id="cart" :class="['btn-cart icon-cart', opened ? 'opened' : '', cartSize ? 'cart-wiggle' : '']" variant="outline" @click="opened = !opened">
      <span v-if="cartSize" class="cart-item">{{ cartSize }}</span>
    </b-btn>
    <div class="cart-list">
      <div class="list-inner">
        <ul v-if="cartSize" class="clist">
          <li v-if="cartObject" class="cgroup active">
            <div class="companyHead d-flex">
              <div class="restaurant-img">
                <img v-if="cartObject.restaurant.logo_filename" :src="getImage('images', cartObject.restaurant.logo_filename, 56, 56)" />
                <img v-else src="/img/emptyCompany.png" alt="" />
              </div>
              <div class="d-flex flex-wrap flex-column mr-auto justify-content-center head-holder">
                <h5 class="h5">{{ cartObject.restaurant.display_name }}</h5>
                <div class="comandWrap">
                  <span v-if="cartObject.restaurant.minimum_delivery_amount" class="mincomand">{{ $t("minOrderAmount") }} {{ cartObject.restaurant.minimum_delivery_amount | price }}</span>
                  <span v-if="cartObject.restaurant.delivery_time" class="mincomand"><i class="icon-timer" /> {{ cartObject.restaurant.delivery_time }} min </span>
                </div>
              </div>
            </div>
            <div class="expanded">
              <div v-for="(product, index) in cartObject.products" :key="index" class="prodItem">
                <!-- <img v-if="product.attributes.image" :src="getProductImage(product.id, product.attributes.image, 100, 100)"/> -->
                <b-row>
                  <b-col sm="7" class="col-6 pr-0 d-flex align-items-center word-break-all">
                    {{ product.name }}<br />
                    <template v-if="_.get(product, 'conditions', []).length && _.get(product, 'conditions.0.discountType', 'NONE') === 'NONE'">
                      {{ _.get(product, 'conditions.0.productNameText') }}
                    </template>
                  </b-col>
                  <b-col sm="5" class="col-6 d-flex justify-content-end">
                    <span class="d-flex align-items-center">
                      {{ product.quantity }} {{ $t("unit") }}
                    </span>
                    <div class="pl-0 btn-group d-flex align-items-center">
                      <b-btn variant="counter" type="button" class="minus" @click="decrementQuantity(product)" />
                      <b-btn variant="counter" type="button" class="plus" @click="incrementQuantity(product)" />
                    </div>
                  </b-col>
                </b-row>
                <div class="cprice">
                  <template v-if="_.get(product, 'conditions', []).length && _.get(product, 'conditions.0.discountType', 'NONE') !== 'NONE'">
                    <span><del class="pr-1 red"><small>{{ (product.price * product.quantity) | price }}</small></del></span>
                    <span><strong>{{ ((product.price - _.get(product, 'conditions.0.parsedRawValue', 0)) * product.quantity) | price }}</strong></span>
                  </template>
                  <template v-else>
                    <span><strong>{{ (product.price * product.quantity) | price }}</strong></span>
                  </template>
                  <b-btn variant="icon" type="button" class="delete" @click="removeCartItem(product)">
                    <i class="icon-delete"></i>
                  </b-btn>
                </div>
              </div>
              <div class="ctax">
                <div v-for="(condition, ccIndex) in cartConditions" :key="ccIndex" class="ctotal">
                  <span class="ctotalText">{{ condition.name }}</span>
                  <span class="ctotalAmount">{{ condition.value | price }}</span>
                </div>
              </div>
            </div>
            <div class="cwrap">
              <div class="ctotal">
                <span class="ctotalText">{{ $t("cartList.subTotal") }}</span>
                <span class="ctotalAmount"><span v-if="hasProductPromotions && subtotalWithoutConditions !== cartSubTotal"><del class="pr-1 red"><small>{{ subtotalWithoutConditions | price }}</small></del></span> {{ cartSubTotal | price }}</span>
              </div>
              <div class="ctotal">
                <span class="ctotalText">{{ $t("cartList.total") }}</span>
                <span class="ctotalAmount">{{ cartTotal | price }}</span>
              </div>
              <b-btn type="button" variant="order" class="large fullwidth" @click="redirectToCheckout">
                <span :title="(cartObject.restaurant.display_name)" class="d-none d-md-block ellipsis">
                  {{ $t('orderFrom.dektop') }} {{ cartObject.restaurant.display_name }}
                </span>
                <span class="d-block d-md-none">
                  {{ $t('orderFrom.mobile') }}
                </span>
              </b-btn>
            </div>
          </li>
        </ul>
        <div v-else class="clist empty">
          <h3 class="title">{{ $t('emptyCart.title') }}</h3>
          <p class="txt">{{ $t('emptyCart.firstp') }}</p>
          <p class="txt">{{ $t('emptyCart.secondp') }}</p>
          <div class="cwrap">
            <div class="ctotal">
              <span class="ctotalText">{{ $t('cartList.total') }}</span>
              <span class="ctotalAmount">{{ 0 | price }}</span>
            </div>
            <b-btn disabled variant="primary" class="btn-large fullwidth uppercase">
              {{ $t('emptyCart.btn') }}
            </b-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data:function(){
      return{
        opened: false,
        cart: {},
        cartConditions: {},
        cartSubTotal: 0,
        subtotalWithoutConditions: 0,
        cartTotal: 0,
        cartObject: undefined,
        loadingCart: false
      }
    },
    computed: {
      cartSize () {
        const cartNR = this._.size(this.cart)
        return cartNR > 99 ? "99+" : cartNR;
      },
      hasProductPromotions(){
        return this.cartObject.products.filter((product) => {
            return this._.get(product, 'conditions', []).length
        }).length
      }
    },
    watch: {
      cart (val){
        this.updateCartObject()
      },
      '$route' () {
        this.opened = false
      },
      opened: {
        handler (val) {
          if(val) this.updateCart()
        }
      }
    },
    mounted() {
      let that = this
      this.$root.$on('openSidebarCart', function() {
        that.opened = true
      })
      this.$root.$on('updateCartObject', function(cartObject){
        that.populateCart(cartObject)
      })
    },
    methods: {
      updateCart () {
        this.$api.getCart().then(response => {
          this.populateCart(response.data);
        })
      },
      populateCart ({content, conditions, subtotal, total, subtotalWithoutConditions})
      {
        this.cart = [];
        this.cartConditions = [];
        this.cartSubTotal = subtotal;
        this.subtotalWithoutConditions = subtotalWithoutConditions;
        this.cartTotal = total;
        for(var cartItem in content) {
          this.cart.push(content[cartItem]);
        }
        for(var condition in conditions)
        {
          this.cartConditions.push({
            name: condition,
            value: this._.get(conditions[condition], 'parsedRawValue', 0)
          })
        }
        this.updateCartObject()
      },
      async updateCartObject () {
        if (this.loadingCart || !this.opened) return;
        this.loadingCart = true
        let fields = [
          'id',
          'display_name',
          'slug',
          'settings',
          'delivery_time',
          'offers_delivery',
          'offers_pickup',
          'enable_later_order',
          'payment_type.label',
          'payment_type.id'
        ];
        let withs = [
          'paymentTypes'
        ];
        let appends = [
          'logo_filename',
          'minimum_delivery_amount',
          'pickup_ranges',
          'delivery_ranges'
        ]
        let clientId = this._.get(this.cart, '0.attributes.client_id')
        if (clientId) {
          await this.$api.getRestaurant(clientId, fields, withs, appends).then(response => {
            this.cartObject = {
              products: this.cart,
              restaurant: this._.get(response, 'data.data')
            }
          }).finally(() => {
            this.loadingCart = false
          })
        } else {
          this.cartObject = undefined
          this.loadingCart = false
        }
      },
      decrementQuantity (product)
      {
        if(product.quantity > 1) {
          this.addToCart(product, product.quantity - 1);
        } else {
          this.removeCartItem(product);
        }
      },
      incrementQuantity (product)
      {
        this.addToCart(product, product.quantity + 1);
      },
      addToCart (product, quantity) {
        let that = this
        this.$api.addToCart(product.id, quantity).then(response => {
          that.$root.$emit('updateCartObject', response.data)
        })
      },
      removeCartItem (product) {
        let that = this
        this.$api.removeFromCart(product.id).then(response => {
          that.$root.$emit('updateCartObject', response.data)
        })
      },
      redirectToCheckout()
      {
        this.$router.push({ name: 'cart' })
      }
    }
  }
</script>
