<template>
  <div v-if="categories.length" :class="['filters', opened ? 'active' :'' , isMin1680 ? 'opened' :'']">
    <button type="button" name="filter-btn" class="filter" @click="opened = !opened">
      <i class="icon-arrow-right" />
      <span class="d-none d-lg-block filter-text">Filtre</span>
    </button>
    <div class="wrap">
      <ul>
        <li v-if="showAll" :class="[(selectedCategory.id === emptyCategory.id ? 'active' : '')]"><b-btn class="btn-filter" @click="selectCategory(emptyCategory)">{{ emptyCategory.title }}</b-btn></li>
        <li v-for="(category, cIndex) in categories" :key="cIndex" :class="[(selectedCategory.id === category.id ? 'active' : '')]">
          <b-btn class="btn-filter" @click="selectCategory(category)">{{ category.title }}</b-btn>
          <ul v-if="_.get(category, 'children', [])">
            <li v-for="(subCategory, sIndex) in category.children" :key="sIndex" :class="[(selectedCategory.id === subCategory.id ? 'active' : '')]">
              <b-btn class="btn-filter" @click="selectCategory(subCategory)">{{ subCategory.title }}</b-btn>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      showAll: {
        type: Boolean,
        default: function () {
          return true
        }
      },
      categories: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data: function() {
      return {
        emptyCategory: {
          id: undefined,
          title: this.$t("restFilter.all")
        },
        selectedCategory: {
          id: undefined,
          title: this.$t("restFilter.all")
        },
        isMin1680: typeof window !== 'undefined' ? window.innerWidth > 1679 : true,
        opened:false
      }
    },
    watch: {
      categories: {
        handler (val) {
          if (this.$store.getters['restaurantType/categoryId']) {
            this.$nextTick(() => {
              let found = Object.values(this.categories).find((category) => category.id === this.$store.getters['restaurantType/categoryId'])
              if (found) {
                this.selectCategory(found)
                this.opened = false;
                this.$store.dispatch('restaurantType/setRestaurantCategoryId', {id: undefined})
              }
            })
          }
        }
      }
    },
    methods: {
      selectCategory(category)
      {
        this.selectedCategory = category
        this.opened = !this.opened;
        this.$emit('change', category)
        if(typeof window !== 'undefined') {
          let elem = document.getElementById('content-container')
          if (elem) window.scrollTo({ top: elem.offsetTop, behavior: 'smooth' });
        }
      }
    }
  };
</script>
