<template>
  <footer>
    <button id="back" type="button" name="to-top" class="back icon-arrow-up" @click="scrollToTop()" />
    <b-container>
      <b-row v-if="counties.length">
        <b-col v-for="(county, cIndex) in counties" :key="cIndex" class="location-around">
          <h3 class="title">
            <nuxt-link :to="{ name: 'restaurants-in-county', params: { county: slugify(county.name) } }">{{ county.name }}</nuxt-link>
          </h3>
          <ul class="location-link">
            <li v-for="(city, ctIndex) in _.get(county, 'cities.data', [])" :key="ctIndex">
              <nuxt-link :to="{ name: 'restaurants-in-city', params: { city: slugify(city.name), county: slugify(county.name) } }">{{ city.name }}</nuxt-link>
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="footer-bottom justify-content-between flex-column flex-lg-row">
        <div class="d-block d-md-flex order-1 order-lg-2 flex-grow-1 ml-auto">
          <ul class="bottom-link">
            <li v-for="(item, index) in pages" :key="index">
              <a v-if="isEmbed()" :href="resolveUrl(item.url)" target="_blank">{{ item.title }}</a>
              <nuxt-link v-else :to="item.url">{{ item.title }}</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="d-flex order-2 order-lg-1 copy">
          &copy; {{ new Date().getFullYear().toString() }} yummy.ro <span class="d-none d-sm-flex">-  Toate drepturile rezervate</span>
        </div>
      </b-row>
    </b-container>
    <PremiumCorner />
  </footer>
</template>
<script>
  export default {
    data: function() {
      return {
        pages: [],
        counties: []
      }
    },
    async mounted() {
      await this.$api.getContents([
        'id',
        'title',
        'slug',
        'meta_title',
        'meta_description',
        'attributes'
      ]).then(result => {
        this.pages = this._.get(result, 'data.data', [])
          .filter((item)=> item.attributes.show_in_footer > 0 )
          .map((item) => {
            let isPage = item?.attributes?.is_page === 1
            item.url = isPage ? { name: 'faq', params: { slug: item.slug } } : { name: 'faq', hash: '#' + item.slug }
            return item
          })
      }).catch(err => {
        console.error(err)
      })
      await this.loadCounties()
    },
    methods:{
      scrollToTop() {
        if(typeof window !== 'undefined') {
          window.scrollTo(0, 0)
        }
      },
      async loadCounties() {
        if(this.isEmbed()) return
        let fields = [
          'id',
          'name',
          'city.id',
          'city.name'
        ]
        let withs = [
          'cities'
        ]
        return this.$api.getCounties(fields, withs, [], {}, true).then((response) => {
          this.counties = this._.get(response, 'data.data')
        })
      },
      resolveUrl(url) {
          let { href } = this.$router.resolve(url)
          return href
      }
    }
  }
</script>
