<template>
  <div v-if="faqs.length" class="contentpage">
    <ul>
      <li v-for="(faq, index) in faqs" :key="index">
        <FaqItem :faq="faq"></FaqItem>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    data: function() {
      return {
        faqs: [],
        categories: [],
      }
    },
    mounted () {
      this.getContents()
    },
    methods: {
      async getContents() {
        let requestedSlug = this.$route.params?.slug
        await this.$api.getContents().then(result => {
          var pages = this._.get(result, 'data.data').filter((page) => {
              let isPage = page?.attributes?.is_page
              if(requestedSlug === undefined) {
                return isPage === undefined || isPage === 0
              } else {
                return page.slug === requestedSlug && isPage === 1
              }
          })
          return pages
        }).then((pages) => {
          if(pages.length < 1) {
            // @TODO: redirect to 404
          }
          pages.map((page) => {
            this.faqs.push({
              show: false,
              ...page
            })
          })
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          setTimeout(() => {
            let hash = this.$route.hash;
            if(typeof window !== 'undefined' && hash)
            {
              var elem = document.querySelector(hash)
              if(elem) {
                return window.scrollTo({ top: document.querySelector(hash).offsetTop - 100, behavior: 'smooth' });
              }
            }
          }, 1000)
        })
      }
    }
  };
</script>
