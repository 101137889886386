<template>
  <section :class="['separator', classes ]">
    <div class="container">
      <div class="ln">
        <div class="orange"> 
        </div>
      </div>
    </div> 
  </section>
</template>
<script>
  export default {
    props:{
      classes:{
        type:String,
        default:function(){
          return "";
        }
      }
    }
  }
</script>
