<template>
  <label class="custom-check">
    <input v-model="status" value="true" unchecked-value="false" type="checkbox" />
    <div class="livrare">
      <div class="inner">
        <div class="icon-holder green"><i class="icon-delivery"></i><span class="circle-btn"></span></div>
        <div class="icon-holder grey"><i class="icon-delivery"></i></div>
      </div>
    </div>
    <div :data-unchecked="$t('header.delivery.checkbox_all_restaurants')" :data-checked="$t('header.delivery.checkbox_only_delivery')" class="message"></div>
    <div class="tipp-unchecked">{{ $t('header.delivery.tipp_unchecked') }}</div>
    <div class="tipp-checked">{{ $t('header.delivery.tipp_checked') }}</div>
  </label>
</template>

<script>
  export default {
    data() {
      return {
        status: false
      }
    },
    watch: {
      status: {
        handler (val) {
          let obj = {
            all: !val
          }
          this.$root.$emit('setRestaurantType', obj)
          this.$store.dispatch('restaurantType/setRestaurantType', obj)
        }
      }
    }
  }
</script>
