<template>
  <div>
    <button :disabled="disabled" :type="type" :class="classes" @click="addToCart($event)">
      <slot>{{ $t('products.add_to_cart') }}</slot>
    </button>
  </div>
</template>
<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: function () {
          return false
        }
      },
      type: {
        type: String,
        default: function () {
          return undefined
        }
      },
      classes: {
        type: String,
        default: function () {
          return undefined
        }
      },
      text: {
        type: String,
        default: function () {
          return undefined
        }
      },
      product: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    methods: {
      addToCart (ev) {
        this.$api.addToCart(this.product.id, 1, true).then(result => {
          if(result.data) {
            this.$emit('success', ev, result.data)
          }
        }).catch((err) => {
          if(err.response.status === 422) {
            this.$bvModal.msgBoxConfirm(this._.get(err, 'response.data.message'), {
              title: this.$t('add_to_cart.title'),
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: this.$t('add_to_cart.continue'),
              cancelTitle: this.$t('add_to_cart.cancel'),
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
                if(value) {
                  this.$api.addToCart(this.product.id, 1, true, true).then(result => {
                    if(result.data) {
                      this.$emit('success', ev, result.data)
                    }
                  })
                }
            })
          } else {
            throw new Error(err)
          }
        })
      }
    }
  }
</script>


