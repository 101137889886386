<template>
  <div id="backdrop" class="backdrop"></div>
</template>
<script>
  export default {
    name:'BackDrop'
  }
</script>


