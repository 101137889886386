<template>
  <transition name="modal-fade">
    <div class="popup-component modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header">
            Modal title

            <button
              type="button"
              class="btn-close"
              @click="close"
            >
              <i class="fa fa-times"></i>
            </button>
          </slot>
        </header>
        <section class="modal-body">
          <slot name="body">
            Modal body
          </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            Modal footer
            <button
              type="button"
              class="btn-green"
              @click="close"
            >
              {{ $t('popup.close') }}
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>
