<template>
  <div>
    <button v-b-modal="modalId" :disabled="disabled" :type="type" :class="classes">
      <slot>{{ text }}</slot>
    </button>
    <b-modal :id="modalId" class="add-to-cart-modal" header-class="px-0 py-0" footer-class="d-block text-center" body-class="px-0 py-0">
      <template v-slot:modal-header="{ close }">
        <b-img-lazy v-if="product.product_image" :fluid="true" :src="getProductImage(product.id, product.product_image, 576, 380)"></b-img-lazy>
        <div v-else class="img-replacer"></div>
        <b-button class="close-btn icon-cancel" @click="close()"></b-button>
      </template>
      <div>
        <h5 class="prod">{{ $t('add_to_cart.select_product') }}</h5>
        <b-list-group>
          <template v-if="productList.length">
            <b-list-group-item
              v-for="(item, index) in productList"
              :key="index"
              :disabled="item.disable_order"
            >
              <b-form-radio v-model="selectedProductIndex" :value="index" name="selectedProduct" class="custom-control-inline">
                <template v-if="_.get(item, 'display_name', '').length">
                  {{ item.display_name }}
                </template>
                <template v-else>
                  {{ item.product_name }}
                </template>
                <template v-if="item.final_price.discount_type === 'NONE' && !_.isNil(item.final_price.free_product)">
                  <br />
                  <b-badge variant="success">
                    {{ $t('add_to_cart.free_product', {product: item.final_price.free_product}) }}
                  </b-badge>
                </template>
              </b-form-radio>
              <b-badge v-if="item.disable_order" variant="warning" pill class="float-right">{{ $t('add_to_cart.unavailable') }}</b-badge>
              <template v-else>
                <span class="float-right">
                  <template v-if="!_.isNil(item.final_price.discount_type) && parseFloat(item.final_price.discount_value) > 0">
                    <span class="new-price">
                      <b-badge variant="danger" pill>
                        <template v-if="item.final_price.discount_type === 'PERCENT'">
                          -{{ item.final_price.discount_value | number({minimumFractionDigits: 0}) }}%
                        </template>
                        <template v-else>
                          -{{ item.final_price.discount_value | price({style: 'currency', currency: 'RON', minimumFractionDigits: 0}) }}
                        </template>
                      </b-badge>
                      {{ item.final_price.price | price }} / {{ item.measurement }}
                    </span><br />
                    <small class="old-price float-right">
                      <del>
                        {{ item.final_price.original_price | price }} / {{ item.measurement }}
                      </del>
                    </small>
                  </template>
                  <template v-else>
                    {{ item.final_price.price | price }} / {{ item.measurement }}
                  </template>
                </span>
                <small v-if="!_.isNil(item.final_price.grant_conditions)" class="d-block pl-4">{{ item.final_price.grant_conditions }}</small>
              </template>
            </b-list-group-item>
          </template>
        </b-list-group>

        <p v-if="_.get(productList, `${selectedProductIndex}.public_info`, '').length" class="px-4 pt-4">
          {{ _.get(productList, `${selectedProductIndex}.public_info`, '') }}
        </p>

        <h5 class="quantity">{{ $t('add_to_cart.choose_quantity') }}</h5>
        <b-list-group>
          <b-list-group-item>
            <b-input-group>
              <template v-slot:prepend>
                <b-button variant="success" @click="updateQuantity(-1)">
                  -
                </b-button>
              </template>
              <b-form-input v-model="selectedQuantity" min="1" max="99" type="number" :placeholder="$t('add_to_cart.quantity')"></b-form-input>
              <template v-slot:append>
                <b-button variant="success" @click="updateQuantity(1)">
                  +
                </b-button>
              </template>
            </b-input-group>
          </b-list-group-item>
        </b-list-group>
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="addToCart(ok)">
          {{ $t('products.add_to_cart') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    type: {
      type: String,
      default: function () {
        return undefined
      }
    },
    classes: {
      type: String,
      default: function () {
        return undefined
      }
    },
    text: {
      type: String,
      default: function () {
        return this.$t('products.add_to_cart')
      }
    },
    product: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      modalId: undefined,
      selectedProductIndex: undefined,
      selectedQuantity: 1
    }
  },
  computed: {
    productList() {
      return [
        this.product,
          ...this.product.associate_products
      ]
    }
  },
  mounted() {
    this.modalId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    for(var i = 0; i < this.productList.length; ++i) {
        if(this.productList[i].disable_order === false) {
            this.selectedProductIndex = i
            break
        }
    }
  },
  methods: {
    addToCart (callback) {
      let that = this
      this.$api.addToCart(this.productList[this.selectedProductIndex].id, this.selectedQuantity, true).then(result => {
        if(result.data) {
          that.updateCart(result.data)
          callback()
        }
      }).catch((err) => {
        if(err.response.status === 422) {
          this.$bvModal.msgBoxConfirm(this._.get(err, 'response.data.message'), {
            title: this.$t('add_to_cart.title'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('add_to_cart.continue'),
            cancelTitle: this.$t('add_to_cart.cancel'),
            footerClass: 'p-2',
            dialogClass: 'confirmation',
            hideHeaderClose: false,
            centered: true
          })
            .then(value => {
              if(value) {
                this.$api.addToCart(this.product.id, 1, true, true).then(result => {
                  if(result.data) {
                    that.updateCart(result.data)
                    callback()
                  }
                })
              }
            })
        } else {
            throw new Error(err)
        }
      })
    },
    updateCart(data) {
      this.$root.$emit('updateCartObject', data)
      this.$root.$emit('openSidebarCart')
    },
    updateQuantity(quantity)
    {
        if(!parseInt(quantity)) return;
        const test = (parseInt(this.selectedQuantity) + parseInt(quantity))
        if(!(test > 99) && !(test < 1)) {
          this.selectedQuantity = test
        } else {
          this.selectedQuantity = 1
        }
    }
  }
}
</script>


