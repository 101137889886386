<template>
  <client-only>
    <header v-if="$auth.loggedIn" class="logged app-header">
      <b-navbar toggleable="lg" type="light" variant="green">
        <b-navbar-toggle target="nav-collapse" />
        <Delivery />
        <Logo />
        <HeaderMenu v-if="isDesktop" />
        <HeaderSearch />
        <!--<Language v-if="isDesktop" />-->
        <Cart />
        <b-collapse v-if="!isDesktop" id="nav-collapse" v-model="showCollapse" class="navbar-collapse">
          <HeaderMenu />
          <!--<Language v-if="!isDesktop" />-->
          <HeaderSecondLine v-if="!isDesktop" />
        </b-collapse>
      </b-navbar>
      <HeaderSecondLine v-if="isDesktop" />
    </header>

    <header v-else class="app-header">
      <b-navbar toggleable="lg" type="light" variant="green">
        <b-navbar-toggle target="nav-collapse" />
        <Delivery />
        <Logo />
        <Authorization v-if="isDesktop" />
        <HeaderSearch />
        <!--<Language v-if="isDesktop" />-->
        <Cart />
        <b-collapse v-if="!isDesktop" id="nav-collapse" v-model="showCollapse" class="navbar-collapse">
          <Authorization />
          <!--<Language v-if="!isDesktop" />-->
        </b-collapse>
      </b-navbar>
    </header>
  </client-only>
</template>
<script>
  export default {
    data: function(){
      return {
        isDesktop: typeof window !== 'undefined' ? window.innerWidth > 991 : true,
        showCollapse: false
      }
    },
    watch: {
      '$route' () {
        this.showCollapse = false
      }
    }
  }
</script>
