<template>
  <div :class="['header-search', opened ? 'opened' : '']">
    <b-btn variant="outline" class="icon-search" @click="opened = !opened" />
    <div class="search-wrap">
      <b-form class="search-form" @submit="onSubmit">
        <b-form-input v-model="form.search" :placeholder="$t('search.placeholder')"></b-form-input>
        <b-button type="submit" class="btn-grey icon-search"></b-button>
      </b-form>
    </div>
  </div>
</template>
<script>
  export default {
    data:function(){
      return {
        opened: false,
        form: {
          search: ''
        }
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()
        console.log(JSON.stringify(this.form))
      }

    }
  }
</script>
