<template>
  <div class="paymentPage">
    <div class="list-inner">
      <div class="pay-card">
        <h2 v-if="cartSize && cartObject" class="title fs-20">{{ $t('Plasare comanda') }} {{ _.get(cartObject, 'restaurant.display_name') }}</h2>

        <ul v-if="cartSize && !cartSubmittedWithSuccess" class="clist">
          <li v-if="cartObject">
            <div class="companyHead d-flex">
              <div class="restaurant-img">
                <img v-if="cartObject.restaurant.logo_filename" :src="getImage('images', cartObject.restaurant.logo_filename, 56, 56)" />
                <img v-else src="/img/emptyCompany.png" alt="" />
              </div>
              <div class="d-flex flex-wrap flex-column justify-content-center head-holder">
                <h5 class="h5">{{ cartObject.restaurant.display_name }}</h5>
                <div class="comandWrap">
                  <span v-if="cartObject.restaurant.minimum_delivery_amount" class="mincomand">{{ $t("minOrderAmount") }} {{ cartObject.restaurant.minimum_delivery_amount | price }}</span>
                  <span v-if="cartObject.restaurant.delivery_time" class="mincomand"><i class="icon-timer" /> {{ cartObject.restaurant.delivery_time }} min </span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div v-else-if="cartSubmittedWithSuccess" class="clist empty">
          <h3 class="title fs-20">{{ $t('cart.success.title') }}</h3>
          <p class="txt">{{ $t('cart.success.text') }}</p>
          <div class="text-center">
            <nuxt-link :to="{ name: 'my-account-subpage', params: {slug: 'istoric-comenzi'}}" class="btn btn-primary mb-3">{{ $t('cart.success.history_button') }}</nuxt-link>
          </div>
        </div>

        <div v-else class="clist empty">
          <h3 class="title fs-20">{{ $t('emptyCart.title') }}</h3>
          <p class="txt">{{ $t('emptyCart.firstp') }}</p>
          <p class="txt">{{ $t('emptyCart.secondp') }}</p>
          <div class="cwrap">
            <div class="ctotal">
              <span class="ctotalText">{{ $t('cartList.total') }}</span>
              <span class="ctotalAmount">{{ 0 | price }}</span>
            </div>
            <!-- <b-btn disabled variant="primary" class="mt-3 btn-large fullwidth uppercase">
              {{ $t('emptyCart.btn') }}
            </b-btn> -->
          </div>
        </div>

        <ul v-if="cartObject">
          <li>
            <div class="expanded">
              <div v-for="(product, index) in cartObject.products" :key="index" class="prodItem">
                <!-- <img v-if="product.attributes.image" :src="getProductImage(product.id, product.attributes.image, 100, 100)"/> -->
                <b-row>
                  <b-col sm="7" class="d-flex align-items-center">
                    {{ product.name }}<br />
                    <template v-if="_.get(product, 'conditions', []).length && _.get(product, 'conditions.0.discountType', 'NONE') === 'NONE'">
                      {{ _.get(product, 'conditions.0.productNameText') }}
                    </template>
                  </b-col>
                  <b-col sm="5" class="d-flex justify-content-end">
                    <span class="d-flex align-items-center">
                      {{ product.quantity }} {{ $t("unit") }}
                    </span>
                    <div class="btn-group d-flex align-items-center">
                      <b-btn variant="counter" type="button" class="minus" @click="decrementQuantity(product)" />
                      <b-btn variant="counter" type="button" class="plus" @click="incrementQuantity(product)" />
                    </div>
                  </b-col>
                </b-row>
                <div class="cprice">
                  <template v-if="_.get(product, 'conditions', []).length && _.get(product, 'conditions.0.discountType', 'NONE') !== 'NONE'">
                    <span>
                      <del class="ml-auto red"><small>{{ (product.price * product.quantity) | price }}</small></del>
                      <strong>{{ ((product.price - _.get(product, 'conditions.0.parsedRawValue', 0)) * product.quantity) | price }}</strong>
                    </span>
                  </template>
                  <template v-else>
                    <span><strong>{{ (product.price * product.quantity) | price }}</strong></span>
                  </template>
                  <b-btn variant="icon" type="button" class="delete" @click="removeCartItem(product)">
                    <i class="icon-delete"></i>
                  </b-btn>
                </div>
              </div>
              <div class="ctax">
                <div v-for="(condition, ccIndex) in cartConditions" :key="ccIndex" class="ctotal">
                  <span class="ctotalText">{{ condition.name }}</span>
                  <span class="ctotalAmount">{{ condition.value | price }}</span>
                </div>
                <div class="ctotal">
                  <span class="ctotalText">{{ $t("cartList.subTotal") }}</span>

                  <span class="ctotalAmount"><span v-if="hasProductPromotions && subtotalWithoutConditions !== cartSubTotal"><del class="pr-1 red">{{ subtotalWithoutConditions | price }}</del></span> {{ cartSubTotal | price }}</span>
                </div>
              </div>
            </div>
            <div class="cwrap">
              <div class="ctotal">
                <span class="ctotalText">{{ $t("cartList.total") }}</span>
                <span class="ctotalAmount">{{ cartTotal | price }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="cartObject && cartSize" class="pay-card voucher pb-4">
      <h4 class="title fs-14 uppercase">{{ $t('cart.voucher.title') }}</h4>
      <input v-model="voucher.code" :class="['form-control voucher', voucher.valid ? 'valid' : 'invalid']" :placeholder="$t('cart.voucher.placeholder')" type="text" name="voucher" />
      <b-btn :disabled="!$auth.loggedIn || ($auth.loggedIn && voucher.loading)" variant="verify" @click="validateVoucher">{{ $t('cart.voucher.btnText') }}</b-btn>
    </div>

    <div v-if="$auth.loggedIn && cartSize" class="pay-card">
      <label for="remarks">
        <h4 class="title fs-14 uppercase">{{ $t('cart.remarks') }}</h4>
        <textarea id="remarks" v-model="remarks" name="remarks" class="form-control v-100"></textarea>
      </label>
    </div>

    <div v-if="$auth.loggedIn && cartObject && cartSize" class="pay-card delivery-methods">
      <h4 class="title fs-14 uppercase">{{ $t('cart.deliveryMethods') }}</h4>
      <div class="groupped group-2">
        <label v-if="cartObject.restaurant.offers_delivery" for="deliveryMethodDelivery" class="custom-radio">
          <input id="deliveryMethodDelivery" v-model="deliveryMethod" type="radio" name="deliveryMethod" value="DELIVERY" />
          <span class="ico" />
          {{ $t('cart.delivery_label') }}
        </label>
        <label v-if="cartObject.restaurant.offers_pickup && (cartObject.restaurant.pickup_ranges && cartObject.restaurant.pickup_ranges.lengt)" for="deliveryMethodPickup" class="custom-radio">
          <input id="deliveryMethodPickup" v-model="deliveryMethod" type="radio" name="deliveryMethod" value="PICKUP" />
          <span class="ico" />
          {{ $t('cart.pickup_label') }}
        </label>
      </div>
    </div>

    <div v-if="$auth.loggedIn && cartObject && cartSize" class="pay-card delivery-methods">
      <h4 v-if="deliveryMethod === 'DELIVERY'" class="title fs-14 uppercase">{{ $t('cart.delivery_method_delivery_options') }}</h4>
      <h4 v-if="deliveryMethod === 'PICKUP'" class="title fs-14 uppercase">{{ $t('cart.delivery_method_pickup_options') }}</h4>
      <div v-if="deliveryMethod === 'DELIVERY'" class="groupped group-2">
        <select v-model="deliveryInterval" class="form-control">
          <option value="0">{{ $t('cart.delivery_method_delivery_options_fast') }}</option>
          <option v-for="(deliveryRange, drIndex) in cartObject.restaurant.delivery_ranges" :key="drIndex" :value="drIndex">{{ $moment(deliveryRange).format('DD.MM HH:mm') }}</option>
        </select>
      </div>
      <div v-if="deliveryMethod === 'PICKUP'" class="groupped group-2">
        <label v-for="(pickupRange, puIndex) in cartObject.restaurant.pickup_ranges" :key="puIndex" :for="`pickup_interval_${puIndex}`" class="custom-radio">
          <input :id="`pickup_interval_${puIndex}`" v-model="pickupInterval" :name="`pickup_interval_${puIndex}`" :value="puIndex" type="radio" />
          <span class="ico" />
          {{ $moment(pickupRange).format('MM/DD HH:mm') }}
        </label>
      </div>
    </div>

    <div v-if="$auth.loggedIn && cartObject && cartSize && deliveryMethod === 'DELIVERY'" class="pay-card addresses pb-3">
      <h4 class="title fs-14 uppercase">{{ $t('cart.myAddresses') }}</h4>
      <addresses :allow-select="true" @addressSelected="addressSelected" />
      <div v-if="getError('delivery_address_id')">
        {{ getError('delivery_address_id') }}
      </div>
    </div>

    <div v-if="$auth.loggedIn && cartObject && cartSize" class="pay-card">
      <div class="payment">
        <h4 class="title fs-14 uppercase">{{ $t('cart.paymentTypes') }}</h4>
        <div :data-notset=" $t('cart.paymetNotSetText') " class="groupped group-3">
          <label v-for="(paymentType, pIndex) in _.get(cartObject, 'restaurant.paymentTypes.data', [])" :key="pIndex" :for="'paymentType' + pIndex" class="custom-radio">
            <input :id="'paymentType' + pIndex" v-model="orderPaymentType" :value="paymentType" name="paymentType" type="radio" />
            <span class="ico"></span>
            {{ paymentType.label }}
          </label>
        </div>
        <div v-if="getError('payment_type_id')">
          {{ getError('payment_type_id') }}
        </div>
      </div>
    </div>

    <div v-else-if="!$auth.loggedIn" class="pay-card">
      <h4 class="title fs-20">{{ $t('cart.login_title') }}</h4>
      <p>{{ $t('cart.login_message') }}</p>
      <nuxt-link v-if="isEmbed()" :to="{ name: 'embed-auth' }" class="btn btn-warning btn-large uppercase fullwidth mb-3">{{ $t('loginForm.loginText') }}</nuxt-link>
      <nuxt-link v-else :to="{ name: 'login' }" class="btn btn-warning btn-large uppercase fullwidth mb-3">{{ $t('loginForm.loginText') }}</nuxt-link>
    </div>

    <div v-if="$auth.loggedIn && cartSize" class="pay-card finalizeOrder">
      <div class="cwrap">
        <div class="ctotal">
          <span class="ctotalText">{{ $t("cartList.total") }}</span>
          <span class="ctotalAmount">{{ cartTotal | price }}</span>
        </div>
      </div>
      <button :disabled="!orderIsEligible" type="button" class="btn btn-order" @click="finalizeOrder">{{ $t('cart.finalizeOrderButton') }}</button>
    </div>
  </div>
</template>
<script>
  import Cart from './Cart';
  export default {
    extends: Cart,
    data:function(){
      return{
        opened: true,
        cart: {},
        errors: {},

        remarks: undefined,
        deliveryMethod: undefined,
        deliveryAddress: undefined,
        orderPaymentType: undefined,
        pickupInterval: 0,
        deliveryInterval: 0,

        cartSubmittedWithSuccess: false,
        voucher: {
          code: undefined,
          valid: undefined,
          loading: false
        }
      }
    },
    computed: {
      orderIsEligible ()
      {
        return !!(this.cartObject && this.cartObject.products && this.cartObject.products.length && (this.deliveryAddress && this.deliveryMethod === 'DELIVERY'|| this.deliveryMethod === 'PICKUP') && this.deliveryMethod && this.orderPaymentType);
      }
    },
    mounted() {
      let that = this
      this.updateCart()
      this.$root.$on('updateCartObject', function(cartObject){
        that.populateCart(cartObject)
      })
    },
    methods: {
      async updateCart () {
        await this.$api.getCart({voucher: this.voucher.code}).then(response => {
          this.populateCart(response.data);
        })
      },
      async finalizeOrder() {
        let that = this;
        let params = [
          this._.get(this.cartObject, 'restaurant.id'),
          this.deliveryMethod,
          this.deliveryAddress,
          this._.get(this.orderPaymentType, 'id'),
          this.voucher.code,
          this.remarks,
          this.pickupInterval,
          this.deliveryInterval
        ]
        await this.$api.submitCart(...params).then(async (response) => {
          await this.updateCart()
          this.showSuccess(this.$t('cart.successfullPlacement'))
          that.cartSubmittedWithSuccess = true;
        }).catch((err) => {
          if(err.response.status === 422){
            that.errors = that._.get(err, 'response.data.errors', {});
            this.showError(Object.values(this.errors))
          } else {
            console.error(err.response)
          }
        });
      },
      validateVoucher () {
        this.voucher.loading = true
        this.$api.validateVoucher({
          code: this.voucher.code
        }).then((result) => {
          let isValid = this._.get(result, 'data.isValid')
          let canUse = this._.get(result, 'data.canUse')
          if(isValid && canUse) {
              this.showSuccess(
                  this.$t('cart.voucher.validVoucher')
              )
              this.voucher.valid = true
              this.updateCart()
          } else if (isValid && !canUse) {
              this.showError(
                  this.$t('cart.voucher.usedVoucher')
              )
              this.voucher.valid = false
              this.voucher.code = ''
          } else {
              this.showError(
                  this.$t('cart.voucher.invalidVoucher')
              )
              this.voucher.valid = false
              this.voucher.code = ''
          }
        }).catch((error) => {
            let isValid = this._.get(error.response, 'data.isValid')
            let canUse = this._.get(error.response, 'data.canUse')
            if(isValid && canUse) {
                this.showSuccess(
                    this.$t('cart.voucher.validVoucher')
                )
                this.voucher.valid = true
            } else if (isValid && !canUse) {
                this.showError(
                    this.$t('cart.voucher.usedVoucher')
                )
                this.voucher.valid = false
                this.voucher.code = ''
            } else {
                this.showError(
                    this.$t('cart.voucher.invalidVoucher')
                )
                this.voucher.valid = false
                this.voucher.code = ''
            }
        }).finally(() => {
          this.voucher.loading = false
        })
      },
      addressSelected (address) {
        this.deliveryAddress = this._.get(address, 'id')
      }
    }
  }
</script>
